import React from "react";
import styles from "./all.module.css";
import { ArtList } from "../../art";
import { Link } from "react-router-dom";

export const All = () => {

  return (
    <div className={ styles.wrapper}>
        <section className={ styles.container}>
            <div className={styles.content}>
            <ArtList/>                  
              </div>
            <div className={styles.sidebar}>
              <p className={styles.name}>Cici Woolf</p>
              <Link className={styles.galleryLinks} to="/all">All 
              </Link>     
              <Link className={styles.galleryLinks}
              to="/teapots">Teapots
              </Link> 
              <Link className={styles.galleryLinks}
              to="/people">People
              </Link> 
              <Link className={styles.galleryLinks}
              to="/animals">Animals
              </Link>  
              <Link className={styles.galleryLinks}
              to="/stilllife">Still Life
              </Link>         

              <p className={styles.city}>Minneapolis &<br/>Buenos Aires
              </p>              

            </div>
                              
        </section>

        <footer className={styles.footer}>             
            <div className={styles.footerLinks}>
              <Link className={[styles.menu_bottom, styles.menu_bottom_last].join(' ')} to="/">Home</Link>
              <Link className={[styles.menu_bottom, styles.menu_bottom_last].join(' ')} to="/contacts">Contact</Link>
            </div>         
        </footer>
    </div>
    
  );
};


