

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Contact } from "../pages/contact/Contact";
import { Home} from "../pages/home/Home";
import { All } from "../pages/art-links/All";
import { Teapots} from "../pages/art-links/Teapots";
import { People } from "../pages/art-links/People";
import { Animals } from "../pages/art-links/Animals";
import { StillLife } from "../pages/art-links/StillLife";

export const Content = () => {
  return (
      <Switch>

        <Route exact path="/">
          <Home/>  
        </Route>

        <Route exact path="/all">
          <All />
        </Route>
        
        <Route exact path="/teapots">
          <Teapots />
        </Route>

        <Route exact path="/people">
          <People />
        </Route>

        <Route exact path="/animals">
          <Animals />
        </Route>

        <Route exact path="/stilllife">
          <StillLife />
        </Route>

        <Route exact path="/contacts">
          <Contact />
        </Route>

        <Route path="*">
          <Redirect
              to={{
                pathname: "/",
              }}
            />      
        </Route>
      </Switch>
  );
};



